<template>
  <div class="conteiner-fluid">
    <div class="row" :class="width == 'sm' ? 'mx-1' : 'mx-9 mt-8'">
      <div class="col-12">
        <div class="row sticky-top pt-2">
          <div :class="[flex_ar, t_ar]" class="col-12 reserve-breadcrumb text-center stepper-text">
            <ul :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
              <button @click="changeStep(true)" v-if="step != 0" :class="f_ar" class="btn-pri"> {{ $t("PrevStep") }}
              </button>
              <li :class="step != 0 ? 'text-muted' : ''"> {{ $t("menu") }} </li>
              <i class="fa breadcrumb-icon mx-5" :class="
                $i18n.locale == 'ar' ? 'fa-arrow-left' : 'fa-arrow-right'
              ">
              </i>
              <li :class="step != 1 ? 'text-muted' : ''"> {{ $t("myCart") }} </li>
              <i class="fa breadcrumb-icon mx-5" :class="
                $i18n.locale == 'ar' ? 'fa-arrow-left' : 'fa-arrow-right'
              ">
              </i>
              <li :class="step != 2 ? 'text-muted' : ''"> {{ $t("Checkout") }} </li>
              <button @click="changeStep" v-if="step != 2 && cartItems.length !== 0" :class="f_ar_reverse"
                class="btn-pri"> {{ step == 2 ? $t("Done") : $t("NextStep") }} </button>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <BusinessProducts :business="business" v-if="step == 0 && business && business.id" />
            <Cart v-if="step == 1" />
            <CheckOut v-if="step == 2" />
            <NoPlaces v-else />
          </div>
          <button @click="changeStep" v-if="step == 0" class="mt-5 w-100 btn-pri-lg"> {{ step == 2 ? $t("Done") :
              $t("NextStep")
          }} </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import BusinessProducts from "@/components/Listings/BusinessProducts.vue";
import Cart from "@/components/order/Cart.vue";
import CheckOut from "@/components/order/CheckOut.vue";
import NoPlaces from "@/components/misc/NoPlaces.vue";
import cookies from "vue-cookies";

export default {
  components: {
    BusinessProducts,
    Cart,
    CheckOut,
    NoPlaces,
  },
  data() {
    return {
      step: 0,
    };
  },
  watch: {
    step: function (newStep, oldStep) {
      if (newStep == 0) {
        this.getBusinessById({
          business_id: this.$route.params.id,
          lang: this.$i18n.locale,
        });
      }
    },
  },
  mounted() {
    console.log(this.$route);
    if (
      cookies.isKey("BusId") &&
      this.$route.params.id != cookies.get("BusId")
    ) {
      this.showMsg({
        close: true,
        text: this.$t("cartWarning"),
        type: "error",
        condition: 5,
        business_id: this.$route.params.id,
      });
    }
    this.getAllCartItems();
    this.getBusinessById({
      business_id: this.$route.params.id,
      lang: this.$i18n.locale,
    });
  },
  methods: {
    ...mapActions(["getBusinessById", "getAllCartItems"]),
    ...mapMutations(["showMsg"]),
    changeStep(prev) {
      prev && prev == true ? this.step-- : this.step++;
    },
  },
  computed: {
    ...mapGetters([
      "loading",
      "business",
      "width",
      "profileLoading",
      "business",
      "cartItems",
    ]),
    t_ar() {
      return this.$i18n.locale == "ar" ? "text-right" : "text-left";
    },
    flex_ar() {
      return this.$i18n.locale == "ar" ? "flex-row-reverse" : "";
    },
    f_ar() {
      return this.$i18n.locale == "ar" ? "f-right" : "f-left";
    },
    f_ar_reverse() {
      return this.$i18n.locale == "ar" ? "f-left" : "f-right";
    },
  },
};
</script>