<template>
  <div class="container px-0">
    <div class="row">
      <div class="col-12 px-0">
        <div class="filter-heading my-3 body" id="AttendeesInfoBox" :class="[t_ar]"> {{ $t("AttendeesInfo") }} </div>
        <form :class="flex_ar" class="row justify-content-center main-card" @submit.prevent="saveAttends()"
          v-if="selectedForm" id="saveAttends" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
          <div v-for="(col, key, i) in columns" :key="i" :class="[`col-md-${col.class}`, 'col-12']" class="px-1">
            <label :class="
              (
                col.required &&
                  (col.required == 'one' ||
                    col.required == 'reservation-one') &&
                  (!selectedData.attenders[firstOne] ||
                    !selectedData.attenders[firstOne][key])
                  ? true
                  : col.required === true || null
              )
                ? ['required', 'input-label', f_ar]
                : ['input-label', f_ar]
            ">{{ $t("col_" + key) }}</label>
            <input v-if="!col.mask" :type="col.type" @blur="validateData(col, key)" :id="'col_' + key"
              :class="{ 'is-invalid': col.error, 'is-valid': col.valid }" :readonly="loading" :required="
                col.required &&
                  (col.required == 'one' || col.required == 'reservation-one') &&
                  (!selectedData.attenders[firstOne] ||
                    !selectedData.attenders[firstOne][key])
                  ? true
                  : col.required === true || null
              " class="form-control text-input-sm border" :placeholder="$t('col_' + key)"
              v-model="selectedForm[key]" />
            <input v-else :type="col.type" @blur="validateData(col, key)" :id="'col_' + key"
              :class="{ 'is-invalid': col.error, 'is-valid': col.valid }" :readonly="loading" v-mask="col.mask"
              :masked="true" :required="
                col.required &&
                  col.required == 'one' &&
                  (!selectedData.attenders[firstOne] ||
                    !selectedData.attenders[firstOne][key])
                  ? true
                  : col.required === true || null
              " class="form-control text-input-sm border" :placeholder="$t('col_' + key)"
              v-model="selectedForm[key]" />
            <small class="text-danger" v-if="col.error"> {{ col.error }} </small>
          </div>
          <div class="col-12 text-left mt-3">
            <button class="btn btn-lg btn-success b-0" type="submit"> {{ $t("saveAttender") }} </button>
            <a class="btn btn-light float-right mt-3" v-if="$root.$len(selectedData.attenders) > 0"
              @click="cancelAttender()"> {{ $t("Cancel") }} </a>
          </div>
        </form>
        <form v-else class="contact-form row text-center main-card" @submit.prevent="submit()">
          <div class="col-12 py-3">
            <button type="button" :disabled="
              loading ||
              selectedData.attendeesNO - $root.$len(selectedData.attenders) <=
              0
            " class="btn btn-outline-success mb-2" @click="attenderForm()">
              <i class="fa fa-plus-circle"></i> {{ $t("addAttender") }} <span class="text-warning" v-if="
                selectedData.attendeesNO -
                $root.$len(selectedData.attenders) >=
                0
              "> ({{ selectedData.attendeesNO - $root.$len(selectedData.attenders) }}) </span>
            </button>
          </div>
          <div class="col-12">
            <ul class="list-group pb-3 radius-1">
              <li class="list-group-item list-group-item-action" v-for="(rs, id, i) in selectedData.attenders" :key="i">
                <div class="row listing-attendee">
                  <div class="col-sm-4">
                    <i class="fa fa-user"></i> {{ rs.name }}
                  </div>
                  <div class="col-sm-4">
                    <i v-if="rs.phone" class="fa fa-phone"></i> {{ rs.phone || "" }}
                  </div>
                  <div class="col-sm-2" v-if="!loading">
                    <i class="fa fa-pencil pointer px-1" @click="attenderForm(id)"></i> &nbsp;&nbsp; <i
                      v-if="$root.$len(selectedData.attenders) > 1 && i > 0" @click="removeAttender(id)"
                      class="fa fa-trash pointer text-danger px-1"></i>
                  </div>
                  <div class="col-sm-2" v-else>
                    <i class="fa fa-spin fa-spinner"></i>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="form-group col-12">
            <div class="form-check">
              <input type="checkbox" class="inlineBlock" required /> &nbsp; {{ $t("agreeConditions") }}&nbsp; <a
                class="pointer" @click="openModal"> {{ $t("conditions") }} </a>
            </div>
          </div>
          <div class="form-group col-12">
            <input type="submit" class="btn btn-success btn-lg" :disabled="loading" :value="$t('submit')" />
          </div>
        </form>
      </div>
    </div>
    <!-- Close container -->
    <div class="modal pb-9" id="termsCond" tabindex="-1" aria-labelledby="termsCondLabel" role="dialog"
      aria-hidden="true">
      <div class="modal-dialog" v-if="business && business.category"
        :class="width == 'sm' ? 'w-100' : ['modal-xl', 'mx-auto']">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title modal-header-custom text-center" id="termsCondLabel"> {{ $t("conditions") }} ({{
                business.name
            }}) </h3>
            <a type="button" class="close" @click="closeModal" aria-label="Close">
              <i class="fa fa-times"></i>
            </a>
          </div>
          <div class="modal-body" :class="t_ar">
            <ul class="list-group">
              <li class="list-group-item"> {{ $t("over12") }} </li>
              <li class="list-group-item"> {{ $t("noDiseases") }} </li>
              <li class="list-group-item"> {{ $t("wearmask") }} </li>
              <li class="list-group-item"> {{ $t("respectSefety") }} </li>
              <li class="list-group-item"> {{ $t("followSupervisors") }} </li>
              <li class="list-group-item" v-if="business && business.category.id == 14"> {{ $t("performAblution") }}
              </li>
              <li class="list-group-item" v-if="business && business.category.id == 14"> {{ $t("bringCarpet") }} </li>
            </ul>
          </div>
          <div class="modal-footer">
            <button type="button" @click="closeModal" class="btn btn-secondary"> {{ $t("Close") }} </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import cookies from "vue-cookies";

export default {
  data() {
    return {
      columns: {
        name: { type: "text", required: true, class: "6" },
        phone: {
          type: "tel",
          required: "one",
          class: "6",
          mask: "49###############",
        },
        email: { type: "email", required: "reservation-one", class: "12" },
        street: { type: "text", required: false, class: "6" },
        houseNumber: { type: "text", required: false, class: "6" },
        postalcode: { type: "number", required: false, class: "6" },
        area: { type: "text", required: false, class: "6" },
      },
      selectedForm: {
        area: "",
        name: "",
        email: "",
        phone: "",
        street: "",
        postalcode: "",
        houseNumber: "",
      },
      selectedData: {
        attenders: {},
      },
      firstOne: null,
      defaultAddress: {},
    };
  },
  mounted() {
    this.getUserAddresses(this.user.api_token).then((res) => {
      this.defaultAddress = this.userAddresses.find(
        (el) => el.id == cookies.get("defaultAddressId")
      );
      this.selectedForm = {
        name: this.user.name,
        phone: this.user.phone || this.defaultAddress.phone,
        email: this.user.email,
        street: this.defaultAddress.street_name,
        houseNumber: this.defaultAddress.apartment_no,
        postalcode: this.defaultAddress.postal_code,
        state: this.defaultAddress.city,
      };
    });
  },
  methods: {
    ...mapActions([
      "getBookingDetails",
      "attendsCheckin",
      "checkPhone",
      "getUserAddresses",
    ]),
    ...mapMutations(["showMsg"]),
    submit() {
      this.attendsCheckin({
        api_token: cookies.get("user_token"),
        business_id: this.business.id || this.$route.params.id,
        attenders: Object.values(this.selectedData.attenders),
      })
        .then((res) => {
          this.showMsg({
            text: this.$t("CheckinSuccessful"),
            type: "success",
          });
          setTimeout(() => {
            this.$router.go();
          }, 1000);
        })
        .catch((err) => [
          this.showMsg({
            text: this.$t("CheckinError"),
            type: "error",
          }),
        ]);
    },
    validateData(col, key) {
      let val = this.selectedForm[key];
      if (col && col.error) col.error = null;
      if (col && col.valid) delete col.valid;
      switch (col.type.toLowerCase()) {
        case "tel":
          if (!empty(val)) {
            col.loading = true;
            this.$forceUpdate();
            this.checkPhone(val)
              .then((res) => {
                if (res.data.data == true) {
                  col.valid = true;
                  this.$forceUpdate();
                } else {
                  $("#col_" + key).select();
                  col.error = this.$t("notVaildNumber");
                }
              })
              .catch((err) => {
                this.$forceUpdate();
              });
          } else this.$forceUpdate();

          break;
        default:
          this.$forceUpdate();
      }
    },
    attenderForm(id) {
      if (!id) {
        this.selectedForm = {
          area: "",
          name: "",
          email: "",
          phone: "",
          street: "",
          postalcode: "",
          houseNumber: "",
        };
        id = Date.now();
      } else
        this.selectedForm = this.$root.$copy(this.selectedData.attenders[id]);
      this.selectedFormId = id;
      if (!this.firstOne) this.firstOne = id;
      this.$forceUpdate();
    },
    removeAttender(id) {
      delete this.selectedData.attenders[id];
    },
    saveAttends() {
      this.selectedData.attenders[this.selectedFormId] = {
        ...this.selectedForm,
      };
      this.cancelAttender();
    },
    cancelAttender() {
      this.selectedForm = null;
      this.selectedFormId = null;
      this.$forceUpdate();
    },
    closeModal() {
      $("#termsCond").modal("hide");
    },
    openModal() {
      $("#termsCond").modal("show");
    },
  },
  computed: {
    ...mapGetters(["loading", "business", "width", "user", "userAddresses"]),
    t_ar() {
      return this.$i18n.locale == "ar" ? "text-right" : "text-left";
    },
    flex_ar() {
      return this.$i18n.locale == "ar" ? "flex-row-reverse" : "";
    },
    f_ar() {
      return this.$i18n.locale == "ar" ? "f-right" : "f-left";
    },
    f_ar_reverse() {
      return this.$i18n.locale == "ar" ? "f-left" : "f-right";
    },
  },
};
</script>