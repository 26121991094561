<template>
  <div class="my-5 container px-0">
    <div class="mainBody-card row mouse" :class="[t_ar,]"
      v-if='cartItems && cartItems.length > 0 && business && business.name'>
      <div class="col-12 col-md-2 px-0 pointer" @click='goProfile(business.id)'>
        <img class="text-center"
          :src="business.media ? business.media[0].thumb : 'https://media.tarkett-image.com/large/TH_24567081_24594081_24596081_24601081_24563081_24565081_24588081_001.jpg'"
          alt="">
      </div>
      <div class="col-md-10 col-12 px-0  privacy-header py-1 pointer" :class="f_ar">
        <div @click='goProfile(business.id)'>{{ business.name }}</div>
        <div class="sub-title py-1" v-html='business.category.name' @click='goCategory(business.category.id)'>
        </div>
      </div>
    </div>
    <div class="cart-header row d-flex justify-content-between align-items-center mt-3 mb-0" :class="[t_ar]">
      <div class="privacy-header">
        <i class="fa fa-shopping-cart mx-1"></i> {{ $t('myCart') }} ({{ cartItems ? cartItems.length : 0 }})
      </div>
      <div>
        <button class="btn-pri-sm" @click='clearCart' v-if='cartItems && cartItems.length > 0'>
          <i class="fa fa-remove"></i> {{ $t('clearCart') }} </button>
      </div>
    </div>
    <div class="row mt-0" v-if='cartItems && cartItems.length > 0'>
      <div class="col-12 px-0">
        <div class="cart-item" :class="$i18n.locale" v-for='item in cartItems' :key='item.id'>
          <div class="pro-header row mb-2 mt-0 justify-content-between flex-row"> {{ item.name }} </div>
          <div class="row price-qyt" v-if='item.price'>
            <div class="col-12 px-0 d-flex justify-content-between"> {{ $t('price') }} <span :class="f_ar_reverse">€ {{
                item.price
            }}</span></div>
            <div class="col-12 px-0 d-flex justify-content-between">
              <span>{{ $t('quantity') }}</span>
              <span> x {{ item.quantity ? item.quantity : 1 }}</span>
            </div>
          </div>
          <div class="row options-lable" v-else>
            <div class="col-12 px-0 d-flex justify-content-between" v-for="(opt, name) in item.options" :key='name'>
              <div>
                <span class="">{{ name }} </span>
                <span class="opt-name mx-2" :class="f_ar_reverse"> {{ opt.label }} </span>
              </div>
              <span class="opt-label"> € {{ opt.price }} </span>
            </div>
            <div class="col-12 px-0 d-flex justify-content-between">
              <span :class="f_ar"> {{ $t('quantity') }}</span>
              <span :class="f_ar_reverse"> x {{ item.quantity ? item.quantity : 1 }}</span>
            </div>
          </div>
          <div class="my-3 pb-1 total">
            <span :class="f_ar">{{ $t('total') }} </span>
            <span :class='f_ar_reverse'>€ {{ calculatePrice(item) }}</span>
          </div>
          <button class="btn-error-small m-0" @click="removeItem(item.product_id)">
            <i class="fa fa-trash-o"></i>
            <!-- {{$t('remove')}} -->
          </button>
        </div>
      </div>
      <div class="cart-footer col-12 px-0 m-0" v-if='!attachOrderRoute && !dineInRoute'>
        <button class="btn-pri-lg btn-block py-2 m-0" :disabled='loading' :class="t_ar" @click='goCheckout'> {{
            $t('proceedToCheckout')
        }} <i class="fa"
            :class="$i18n.locale == 'ar' ? ['fa-chevron-left', 'f-left'] : ['fa-chevron-right', 'f-right']"></i>
        </button>
      </div>
    </div>
    <div v-else class="row" :class="width == 'sm' ? 'mx-1' : 'mx-9'">
      <NoPlaces class="col-12" :msg="$t('cartEmpty')" :imgWith="true" :img="require('../../assets/images/empty.png')">
      </NoPlaces>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import NoPlaces from '@/components/misc/NoPlaces';
import ResultCard from '@/components/Listings/PopProduct';
import cookies from 'vue-cookies';

export default {
  data() {
    return {
      allItems: [],
    }
  },
  mounted() {
    this.getAllCartItems();
    this.getBusinessById({
      business_id: cookies.get('BusId'),
      lang: this.$i18n.locale
    })
  },
  computed: {
    ...mapGetters(['cartItems', 'width', 'business', 'loading']),
    t_ar() {
      return this.$i18n.locale == 'ar' ? 'text-right' : 'text-left';
    },
    t_ar_reverse() {
      return this.$i18n.locale == 'ar' ? 'text-left' : 'text-right';
    },
    f_ar() {
      return this.$i18n.locale == 'ar' ? 'f-right' : 'f-left';
    },
    f_ar_reverse() {
      return this.$i18n.locale == 'ar' ? 'f-left' : 'f-right';
    },
    attachOrderRoute() {
      return this.$route.name == 'AttachOrder';
    },
    dineInRoute() {
      return this.$route.name == 'DineIn';
    },
  },
  components: {
    NoPlaces,
    ResultCard
  },
  methods: {
    ...mapMutations(['showMsg']),
    ...mapActions(['showProduct', 'getAllCartItems', 'getBusinessById']),
    goCheckout() {
      this.$router.push({
        name: 'CheckOut',
        params: {
          locale: this.$i18n.locale,
          id: cookies.get('BusId'),
        }
      })
    },
    clearCart() {
      this.$store.commit('showMsg', {
        close: true,
        text: this.$t('clearCartSure'),
        condition: 5,
        type: 'warning',
      })
    },
    calculatePrice(item) {
      let total = 0;
      if (item.options) {
        for (const [key, value] of Object.entries(item.options)) {
          total += parseFloat(value.price) * item.quantity;
        }
        return (total).toFixed(2)

      } else {
        return (item.price * item.quantity).toFixed(2)
      }
    },
    removeItem(id) {
      this.$store.commit('removeFromVCart', id);
      this.getAllCartItems();
      this.$store.commit('showMsg', {
        text: this.$t('itemRemoved'),
        type: 'success'
      })
    },
    goProfile(id) {
      this.$router.push({
        name: 'BusinessProfile',
        params: {
          locale: this.$i18n.locale,
          id: id,
        }
      })
    },
    goCategory(id) {
      this.$router.push({
        name: 'SearchResults',
        params: {
          categoryId: id,
        }
      })
    }
  }
}
</script>
