<template>
  <div class="container">
    <div class="row  justify-content-center px-0" :class="width == 'sm' ? 'mx-1' : 'mx-3'">
      <div class="col-12  card-container p-3" v-if="business && business.id">
        <div class="row">
          <div :class="[flex_ar, t_ar]" class="col-12 px-1 px-md-3 reserve-breadcrumb">
            <ul :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
              <li>
                <router-link :to="{ path: '/' }">
                  <a class="pointer">{{ $t("Home") }}</a>
                </router-link>
              </li>
              <i class="fa breadcrumb-icon" :class="
                $i18n.locale == 'ar' ? 'fa-chevron-left' : 'fa-chevron-right'
              ">
              </i>
              <li @click="
                $router.push({
                  name: 'SearchResults',
                  params: { locale: $i18n.locale },
                })
              ">
                <a class="pointer">{{ $t("Reservations") }}</a>
              </li>
              <i class="fa breadcrumb-icon" :class="
                $i18n.locale == 'ar' ? 'fa-chevron-left' : 'fa-chevron-right'
              ">
              </i>
              <li>
                <a class="text-muted">{{ business.name }}</a>
              </li>
            </ul>
          </div>
        </div>
        <!-- selected business view   -->
        <div class="row">
          <div class="col-12 px-0">
            <div class="mainBody-card row mouse" :class="flex_ar" v-if="!profileLoading">
              <div class="col-12 col-md-2" @click="goProfile(business.id)">
                <img class="" :src="
                  business.media
                    ? business.media[0].thumb
                    : 'https://media.tarkett-image.com/large/TH_24567081_24594081_24596081_24601081_24563081_24565081_24588081_001.jpg'
                " alt="" />
              </div>
              <div class="col-md-9 col-12 privacy-header m-0 text-start py-1" :class="t_ar"
                v-if="business && business.id">
                <div class="pointer" @click="goProfile(business.id)"> {{ business.name }} </div>
                <div class="sub-title py-1 m-0">
                  <router-link v-html="business.category.name" :to="{
                    name: 'CategoryId',
                    params: {
                      categoryId: business.category.id,
                      locale: $i18n.locale,
                    },
                  }">
                  </router-link>
                </div>
              </div>
            </div>
            <NoPlaces v-else />
            <div class="row text-center">
              <div class="col-12 mt-4" v-if="business && business.id">
                <div class="btn-group btn-group-primary mb-4" role="group">
                  <button style="height: auto !important" v-if="business.allow_checkin" type="button"
                    class="btn btn-outline-primary" @click="goCheckin" :class="{ active: $route.name == 'Checkin' }">
                    <i class="fa fa-check"></i> {{ $t("Checkin") }} </button>
                  <button v-if="business.allow_booking" style="height: auto !important" type="button"
                    class="btn btn-outline-info" @click="goReserve" :class="{ active: $route.name == 'Reservation' }">
                    <i class="fa fa-ticket"></i> {{ $t("Reservation") }} </button>
                  <button v-if="business.has_donation" style="height: auto !important" type="button"
                    class="btn btn-outline-success" @click="goDonate" :class="{ active: $route.name == 'Donate' }">
                    <i class="fa fa-paypal"></i> {{ $t("Donate") }} </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Reservation v-if="$route.name == 'Reservation' && business && business.id" />
        <Checkin v-if="$route.name == 'Checkin' && business && business.id" />
        <Donate v-if="
          $route.name == 'Donate' &&
          business &&
          business.has_donation &&
          (business.paypal_donation_mail || business.paypal_donation_url)
        " :email="business.paypal_donation_mail" :url="business.paypal_donation_url" @submitted="
  () => {
    $route.name == 'Donate';
  }
" />
      </div>
      <NoPlaces v-else />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Reservation from "@/components/order/Reservation.vue";
import Checkin from "@/components/order/Checkin.vue";
import Donate from "@/components/misc/Donate.vue";
import NoPlaces from "@/components/misc/NoPlaces.vue";

export default {
  components: {
    Reservation,
    Checkin,
    Donate,
    NoPlaces,
  },

  mounted() {
    this.getBookingDetails({
      lang: this.$i18n.locale,
      business_id: this.$route.params.id,
    })
      .then((res) => { })
      .catch((err) => {
        console.log(err);
        this.getBusinessById({
          lang: this.$i18n.locale,
          business_id: this.$route.params.id,
        });
      });
  },
  methods: {
    ...mapActions(["getBookingDetails", "getBusinessById"]),
    goProfile(id) {
      this.$router.push({
        name: "BusinessProfile",
        params: {
          locale: this.$i18n.locale,
          id: id,
        },
      });
    },
    goDonate(id) {
      this.$router.push({
        name: "Donate",
        params: {
          locale: this.$i18n.locale,
          id: this.business.id || this.$route.params.id,
        },
      });
    },
    goReserve(id) {
      this.$router.push({
        name: "Reservation",
        params: {
          locale: this.$i18n.locale,
          id: this.business.id || this.$route.params.id,
        },
      });
    },
    goCheckin(id) {
      this.$router.push({
        name: "Checkin",
        params: {
          locale: this.$i18n.locale,
          id: this.business.id || this.$route.params.id,
        },
      });
    },
  },
  computed: {
    ...mapGetters(["loading", "business", "width", "profileLoading"]),
    t_ar() {
      return this.$i18n.locale == "ar" ? "text-right" : "text-left";
    },
    flex_ar() {
      return this.$i18n.locale == "ar" ? "flex-row-reverse" : "";
    },
    f_ar() {
      return this.$i18n.locale == "ar" ? "f-right" : "f-left";
    },
    f_ar_reverse() {
      return this.$i18n.locale == "ar" ? "f-left" : "f-right";
    },
  },
};
</script>