var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[(_vm.tickets && _vm.tickets.code)?_c('div',{staticClass:"text-center",class:_vm.guestTicketsRoute ? 'mx-9 mt-8 card-container' : ''},[(!_vm.loaded)?_c('img',{attrs:{"src":"/assets/images/loading.gif","alt":""}}):_c('div',{staticClass:"row justify-content-center",class:_vm.guestTicketsRoute ? 'p-5' : ''},[_c('div',{staticClass:"col-12 d-flex my-3"},[_c('button',{staticClass:"btn-success w-50",attrs:{"disabled":_vm.printStatus && _vm.print},on:{"click":function($event){return _vm.printing()}}},[_c('i',{staticClass:"fa",class:_vm.printStatus ? 'fa-spin fa-spinner' : 'fa-print',staticStyle:{"height":"auto !important"}}),_vm._v(" "+_vm._s(_vm.$t("print"))+" ")]),_c('button',{staticClass:"btn-success w-50",attrs:{"disabled":_vm.printStatus && _vm.print},on:{"click":function($event){return _vm.pdf()}}},[_c('i',{staticClass:"fa",class:_vm.printStatus ? 'fa-spin fa-spinner' : 'fa-file-pdf',staticStyle:{"height":"auto !important"}}),_vm._v(" "+_vm._s(_vm.$t("pdf"))+" ")])])]),_c('iframe',{directives:[{name:"show",rawName:"v-show",value:(_vm.loaded),expression:"loaded"}],staticClass:"w-100",class:_vm.guestTicketsRoute ? 'p-5' : '',staticStyle:{"height":"550px"},attrs:{"src":_vm.$root.$domain +
            'businesses/booking/scan/' +
            _vm.tickets.id +
            '/' +
            _vm.tickets.code,"frameborder":"0"},on:{"load":function($event){_vm.loaded = true;
            _vm.$forceUpdate();}}})]):_vm._e(),(_vm.print && _vm.tickets)?_c('iframe',{staticClass:"w-100",staticStyle:{"opacity":"0"},attrs:{"id":"printing","src":_vm.$root.$domain +
          'businesses/booking/' +
          _vm.print +
          '/' +
          _vm.tickets.id +
          '/' +
          _vm.tickets.code +
          '?lang=' +
          _vm.$i18n.locale,"frameborder":"0"},on:{"load":function($event){_vm.printStatus = false;
          _vm.$forceUpdate();}}}):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }