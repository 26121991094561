<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div
          class="text-center"
          :class="guestTicketsRoute ? 'mx-9 mt-8 card-container' : ''"
          v-if="tickets && tickets.code"
        >
          <img
            class=""
            src="/assets/images/loading.gif"
            v-if="!loaded"
            alt=""
          />
          <div
            class="row justify-content-center"
            v-else
            :class="guestTicketsRoute ? 'p-5' : ''"
          >
            <div class="col-12 d-flex my-3">
              <button
                :disabled="printStatus && print"
                class="btn-success w-50"
                @click="printing()"
              >
                <i
                  :class="printStatus ? 'fa-spin fa-spinner' : 'fa-print'"
                  class="fa"
                  style="height: auto !important"
                ></i>
                {{ $t("print") }}
              </button>
              <button
                :disabled="printStatus && print"
                class="btn-success w-50"
                @click="pdf()"
              >
                <i
                  :class="printStatus ? 'fa-spin fa-spinner' : 'fa-file-pdf'"
                  class="fa"
                  style="height: auto !important"
                ></i>
                {{ $t("pdf") }}
              </button>
            </div>
          </div>
          <iframe
            :class="guestTicketsRoute ? 'p-5' : ''"
            v-show="loaded"
            @load="
              loaded = true;
              $forceUpdate();
            "
            class="w-100"
            style="height: 550px"
            :src="
              $root.$domain +
              'businesses/booking/scan/' +
              tickets.id +
              '/' +
              tickets.code
            "
            frameborder="0"
          >
          </iframe>
        </div>

        <iframe
          id="printing"
          style="opacity: 0"
          v-if="print && tickets"
          @load="
            printStatus = false;
            $forceUpdate();
          "
          class="w-100"
          :src="
            $root.$domain +
            'businesses/booking/' +
            print +
            '/' +
            tickets.id +
            '/' +
            tickets.code +
            '?lang=' +
            $i18n.locale
          "
          frameborder="0"
        >
        </iframe>
      </div>
    </div>
  </div>
</template>

<script>
import NoPlaces from "@/components/misc/NoPlaces.vue";
import { mapActions } from "vuex";
import cookies from "vue-cookies";

export default {
  components: {
    NoPlaces,
  },
  watch: {
    loaded: function (newL, oldL) {
      newL == true
        ? this.$store.dispatch("stopLoading")
        : this.$store.dispatch("startModalLoading");
    },
  },
  data() {
    return {
      loaded: false,
      printStatus: false,
      print: null,
      guestTickets: null,
    };
  },
  props: ["mytickets"],
  mounted() {
    this.$store.dispatch("startModalLoading");
    if (!this.mytickets || !this.mytickets.code) {
      this.$route.params.reservation
        ? (this.guestTickets = { ...this.$route.params.reservation })
        : this.getMyReservations({
            lang: this.$i18n.locale,
            reservation_id: this.$route.params.reservation_id,
            api_token: cookies.get("guest"),
          })
            .then((res) => {
              this.guestTickets = { ...res.data.data };
            })
            .catch((err) => console.log(err));
    }
  },
  methods: {
    ...mapActions(["getMyReservations"]),
    printing(type) {
      this.print = null;
      this.$forceUpdate();
      this.$nextTick(() => {
        this.printStatus = true;
        this.print = type || "print";
        this.$forceUpdate();
      });
    },
    pdf() {
      this.printing("pdf");
      window.setTimeout(() => {
        this.printStatus = false;
        this.$forceUpdate();
      }, 1500);
    },
  },
  computed: {
    guestTicketsRoute() {
      return this.$route.name == "Tickets";
    },
    tickets() {
      return this.mytickets && this.mytickets
        ? this.mytickets
        : this.guestTickets;
    },
  },
};
</script>